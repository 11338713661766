<template>
  <div class="roleInfo">
    <div class="actionBar">
      <a-input-search
        size="large"
        v-model="listParams.keyword"
        placeholder="请输入关键词搜索"
        enter-button="查询"
        style="width: 350px"
        allowClear
        @search="onSearch"
      />
      <a-button
        icon="plus"
        type="primary"
        @click="showModal"
        size="large"
        v-if="handLists.top.indexOf('add') != -1"
      >
        添加推广位</a-button
      >
    </div>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :from="addFromData"
      >
        <a-form-item label="推广名称">
          <a-input v-model="addFromData.name" />
        </a-form-item>
        <a-form-item label="简介">
          <a-textarea
            v-model="addFromData.remark"
            :auto-size="{ minRows: 4, maxRows: 6 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="index" slot-scope="text, record, index">
        {{ index }}
      </span>

      <span slot="action" slot-scope="text, record">
        <!-- <a-button type="primary" size="small" @click="goSub(record.id)">
          管理页面
        </a-button> -->
        <span v-if="handLists.right.indexOf('mgmtPage') != -1">
          <FontIcon
            @click.native="goSub(record.id)"
            title="管理页面"
            type="icon-zu"
            size="22"
          ></FontIcon>

          <a-divider type="vertical" />
        </span>

        <span v-if="handLists.right.indexOf('edit') != -1">
          <FontIcon
            @click.native="editMenu(record)"
            title="修改"
            type="icon-xiugai"
            size="22"
          ></FontIcon>
          <a-divider type="vertical" />
        </span>

        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right.indexOf('del') != -1">
        
          <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import { slideIndex, slideSlideSave, slideSlideDel } from "@/apis/index";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "推广名称",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "简介",
    dataIndex: "remark",
    scopedSlots: { customRender: "remark" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      count: 0,

      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },
  created() {
    this.getSlideIndex();
  },
  methods: {
    goSub(id) {
      this.$router.push({
        path: "/contents/AdverMgt",
        query: { id },
      });
    },
    showModal() {
      this.modalTitle = "新增推广位";
      (this.addFromData = {
        id: "",
        name: "",
        remark: "",
      }),
        (this.visible = true);
    },
    handleOk() {
      this.confirmLoading = true;
      slideSlideSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.getSlideIndex();
          this.visible = false;
        }
        this.confirmLoading = false;
      });
    },
    handleCancel() {
      this.visible = false;
    },
    editMenu(record) {
      this.modalTitle = "修改推广信息";
      this.addFromData = {
        id: record.id,
        name: record.name,
        remark: record.remark,
      };
      this.visible = true;
    },
    confirm(rec) {
      slideSlideDel({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getSlideIndex();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getSlideIndex();
    },
    getSlideIndex() {
      slideIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getSlideIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch() {
      this.listParams.page = 1;
      this.getSlideIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.roleInfo {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
